import React , {useState , useEffect, useRef} from 'react';
import '../HomeScreen/Home.css';
import './faqs.css';
import logo from "../../images/logo.jpeg"
import parcel from "../../images/parcel.png"
import image1 from "../../images/product1.jpg"
import image2 from "../../images/product2.jpg"
import image3 from "../../images/product3.jpg"
import image4 from "../../images/product4.jpg"
import image5 from "../../images/product5.jpg"
import about from "../../images/About.jpeg"
import contact from "../../images/Contact.jpg"



import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { Button } from 'react-bootstrap';
import { Link } from 'react-scroll';
import { Link as RouterLink } from 'react-router-dom';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faArrowUp, faArrowDown } from '@fortawesome/free-solid-svg-icons';
import { FiArrowUp, FiArrowDown } from 'react-icons/fi';
import { FaShoppingBag } from 'react-icons/fa';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';




import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import { useNavigate } from 'react-router-dom';
 


const FAQ = () => {

    const navigate = useNavigate()

    const navigateToHomePage = () => {
        navigate('/?fromContact=true');
      };



    const faqs = [
        {
            question: "Where can I track my order from?",
            answer: "There is a tracking number that is provided to you on making a purchase. It's shown on the webpage after the order is placed and can also be found in the email sent on making an order."
        },
        {
            question: "What payment methods do you accept?",
            answer: "Bank Transfer and Cash on Delivery (COD)"
        }, 
        {
            question : "Do you offer same-day delivery?",
            answer : "Our products usually take 5-7 working days but same-day delivery is offered on paying some extra delivery charges"
        },
        {
            question : "What shipping fees do you charge?",
            answer : "The shipping fees depends on the location the order is being delivered to.\nIt is calculated once the shipping address is entered.It usually ranges between $0.99 to $3.99"
        },
        {
            question : "What is the process of returning a product?",
            answer : "Products are returned if they are not as described.\nA customer can contact us and enter ba return request with pictures showing the product is different from the one they ordered"
        },
        {
            question : "Can I have my order gift wrapped?",
            answer : "Certainly! You just have to select the gift option on the checkout page"
        },

    ];


    return (
        <div>
            <div className="navbar px-5">
                <div className="d-flex align-items-center">
                <img className="logo" src={logo} alt="Logo" />
                <div className="ms-3">
                    <h2 className="navbar-heading" style={{marginTop:'3%'}}>ESPIRIT LLC</h2>
                    <p className="navbar-text text-white" style={{marginTop:'-3%'}}>Reliable Products, Reliable Partnership</p>
                </div>
                </div>
                <a className="contact-button" onClick={navigateToHomePage}>
                    Contact
                </a>
            </div>
            <div className="sub-navbar bg-light py-2 px-5">
                <div className="row align-items-center px-0">
                <div className="col-12 col-md-6">
                    <ul className="nav">
                    <li className="nav-item">
                        <RouterLink className="nav-link" to="/">Home</RouterLink>
                    </li>
                    <li className="nav-item">
                        <RouterLink to="/Shop" className="nav-link">Shop</RouterLink>
                    </li>
                    <li className="nav-item">
                        <RouterLink className="nav-link" to="/About">About Us</RouterLink>
                    </li>
                    <li className="nav-item">
                        <RouterLink className="nav-link active" to="/FAQs">
                        FAQs
                        <span className="active-indicator"></span>
                        </RouterLink>
                    </li>
                        <li className="nav-item">
                        <RouterLink to="/Blog" className="nav-link" href="/">Blog</RouterLink>
                    </li>
                    </ul>
                </div>
                <div className="col-12 col-md-6 text-md-end">
                    <span className="email-text">admin@espiritllc.com</span>
                    <FaShoppingBag className="shopping-bag-icon" color="black" size={20} style={{ fill: 'none', stroke: 'black', strokeWidth: 20 , marginBottom: 5 }} />
                </div>
                </div>
            </div>

            <div className="container d-flex flex-column justify-content-center align-items-center" style={{ marginTop: "9rem" }}>
                <h2 className="cursive-heading">FAQs</h2>
                <button className="contact-btn btn btn-outline-dark btn-lg" onClick={navigateToHomePage}>Contact Us</button>
            </div>

            <div className="container faq my-5 py-5">
                <div className="row">
                    {/* Render the questions and answers */}
                    {faqs.map((faq, index) => (
                    <div key={index} className="col-md-6 px-5">
                        <h3 style={{fontFamily: "'Times New Roman', cursive"}}>{faq.question}</h3>
                        <br></br>
                        <p style={{ whiteSpace: 'pre-line' , paddingBottom:"2rem" ,fontFamily: "'Raleway', sans-serif" }}>{faq.answer}</p>
                    </div>
                    ))}
                </div>
            </div>




            {/* FOOTER JSX */}
            <footer className="footer">
                <div className="footer-content">
                    <div className="footer-column mb-4">
                    <div>
                        <h2 className="footer-heading">Espirit LLC</h2>
                    </div>
                    </div>
                    <div className="footer-column mb-4">
                    <div>
                        <p className="footer-heading">Address</p>
                        <br />
                        <p>34 N Franklin Ave Ste 687 2116</p>
                        <p>Pinedale, Wyoming</p>
                        <p>82941</p>
                        <p>©2018 by ESPIRIT LLC</p>
                    </div>
                    </div>
                    <div className="footer-column">
                    <div>
                        <p className="footer-heading">Contact</p>
                        <br />
                        <p>admin@espiritllc.com</p>
                        <p>+13022084083</p>
                    </div>
                    </div>
                </div>
            </footer>



        </div>
    )

}

export default FAQ;