import React , {useState , useEffect, useRef} from 'react';
import logo from "../../images/logo.jpeg"
import image1 from "../../images/product1.jpg"
import image2 from "../../images/product2.jpg"
import image3 from "../../images/product3.jpg"
import image4 from "../../images/product4.jpg"
import image5 from "../../images/product5.jpg"
import "./Shop.css"


import { Link as RouterLink } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { Link } from 'react-scroll';
import { useHistory } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import { animateScroll as scroll } from 'react-scroll';

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faArrowUp, faArrowDown } from '@fortawesome/free-solid-svg-icons';
import { FiArrowUp, FiArrowDown } from 'react-icons/fi';
import { FiMail } from 'react-icons/fi';
import { FaRupeeSign, FaShoppingBag } from 'react-icons/fa';
import 'bootstrap/dist/css/bootstrap.min.css';



import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import { useNavigate } from 'react-router-dom';


const Shop = () =>{

    const navigate = useNavigate();

    const navigateToHomePage = () => {
        navigate('/?fromContact=true');
      };
      
      
        // PRODUCTS ARRAY 
  // ______________________________________________________________________


  const products = [
    {
      image: image1,
      name: 'Multi Purpose Blender Black',
      description: 'This is used for making juices',
      price: 19.99,
      bestselling: 'yes'
    },
    {
      image: image2,
      name: 'Clipper For Mobile Phones',
      description: 'Clips for mobile cameras and trigger purposes',
      price: 23.75,
      bestselling: 'yes'

    },
    {
      image: image3,
      name: 'Multi Color Hair Brushes',
      description: 'Soft and strong hair brushes for every kind of hair',
      price: 15.50,
      bestselling: 'no'
    },
    {
      image: image4,
      name: 'FootWear Grey & White',
      description: 'Shoes for your everywhere to go.',
      price: 12.99,
      bestselling: 'yes'

    },
    {
      image: image5,
      name: 'Canon Camera Black Color',
      description: 'High end camera for high end pictures',
      price: 29.99,
      bestselling: 'no'
    },
    {
      image: image1,
      name: 'Multi Purpose Blender Black',
      description: 'This is used for making juices',
      price: 17.49,
      bestselling: 'yes'

    },
    {
      image: image2,
      name: 'Clipper For Mobile Phones',
      description: 'Clips for mobile cameras and trigger purposes',
      price: 9.99,
      bestselling: 'yes'
    },
    {
      image: image3,
      name: 'Multi Color Hair Brushes',
      description: 'Soft and strong hair brushes for every kind of hair',
      price: 21.25,
      bestselling: 'no'
    },
    {
      image: image4,
      name: 'FootWear Grey & White',
      description: 'Shoes for your everywhere to go.',
      price: 16.75,
      bestselling: 'no'
    },
    {
      image: image5,
      name: 'Canon Camera Black Color',
      description: 'High end camera for high end pictures',
      price: 14.99,
      bestselling: 'no'
    },
    {
      image: image1,
      name: 'Multi Purpose Blender Black',
      description: 'This is used for making juices',
      price: 27.50,
      bestselling: 'yes'
    },
    {
      image: image2,
      name: 'Clipper For Mobile Phones',
      description: 'Clips for mobile cameras and trigger purposes',
      price: 18.99,
      bestselling: 'yes'
    },
    {
      image: image3,
      name: 'Multi Color Hair Brushes',
      description: 'Soft and strong hair brushes for every kind of hair',
      price: 11.99,
      bestselling: 'no'
    },
    {
      image: image4,
      name: 'FootWear Grey & White',
      description: 'Shoes for your everywhere to go.',
      price: 24.50,
      bestselling: 'no'
    },
    {
      image: image5,
      name: 'Canon Camera Black Color',
      description: 'High end camera for high end pictures',
      price: 8.95,
      bestselling: 'yes'
    },
    {
      image: image1,
      name: 'Multi Purpose Blender Black',
      description: 'This is used for making juices',
      price: 19.99,
      bestselling: 'no'
    },
    {
      image: image2,
      name: 'Clipper For Mobile Phones',
      description: 'Clips for mobile cameras and trigger purposes',
      price: 13.25,
      bestselling: 'no'
    },
    {
      image: image3,
      name: 'Multi Color Hair Brushes',
      description: 'Soft and strong hair brushes for every kind of hair',
      price: 22.75,
      bestselling: 'yes'
    },
    {
      image: image4,
      name: 'FootWear Grey & White',
      description: 'Shoes for your everywhere to go.',
      price: 10.99,
      bestselling: 'no'
    },
    {
      image: image5,
      name: 'Canon Camera Black Color',
      description: 'High end camera for high end pictures',
      price: 16.50,
      bestselling: 'no'
    },
  ];


  const productContainerRef = useRef(null);
  const [displayedProducts, setDisplayedProducts] = useState(products.slice(0, 8));
  const [showAll, setShowAll] = useState(false);

  const handleLoadMore = () => {
    setShowAll(!showAll);
    if (!showAll) {
      setDisplayedProducts(products);
    } else {
      setDisplayedProducts(products.slice(0, 8));

      // Scroll to the product section after sliding up (when Show Less is clicked)
      productContainerRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  };



  //TO PRODUCT SECTION
  const navigateToProduct = (index) => {
    const product = displayedProducts[index];
    navigate('/Product', { state: { products: displayedProducts, index: index } });
  };
  


    return(

        <div>
            {/* Navbar */}
            <div className="navbar px-5">
            <div className="d-flex align-items-center">
                <img className="logo" src={logo} alt="Logo" />
                <div className="ms-3">
                <h2 className="navbar-heading" style={{marginTop:'3%'}}>Espirit LLC</h2>
                <p className="navbar-text text-white" style={{marginTop:'-3%'}}>Reliable Products, Reliable Partnership</p>
                </div>
            </div>
            <a className="contact-button" onClick={navigateToHomePage}>
                Contact
            </a>
            </div>
            <div className="sub-navbar bg-light py-2 px-5">
            <div className="row align-items-center px-0">
                <div className="col-12 col-md-6">
                <ul className="nav">
                    <li className="nav-item">
                    <RouterLink to = "/" className="nav-link">Home</RouterLink>
                    </li>
                    <li className="nav-item">
                    <RouterLink className="nav-link active" to="/shop">
                        Shop
                        <span className="active-indicator"></span>
                    </RouterLink>
                    </li>
                    <li className="nav-item">
                    <RouterLink className="nav-link" to="/About">About Us</RouterLink>
                    </li>
                    <li className="nav-item">
                    <RouterLink className="nav-link" to="/FAQs">FAQs</RouterLink>
                    </li>
                    <li className="nav-item">
                    <RouterLink className="nav-link" to="/Blog">Blog</RouterLink>
                    </li>
                </ul>
                </div>
                <div className="col-12 col-md-6 text-md-end">
                <span className="email-text">admin@espiritllc.com</span>
                <FaShoppingBag className="shopping-bag-icon" color="black" size={20} style={{ fill: 'none', stroke: 'black', strokeWidth: 20 , marginBottom: 5 }} />
                </div>
            </div>
            </div>


            {/* PRODUCT JSX */}

            <div className="container product-container pt-5 px-5" style={{marginTop:'7rem'}} ref={productContainerRef}>
            <TransitionGroup className="row row-cols-1 row-cols-md-2 row-cols-lg-4 mx-4">
              {displayedProducts.map((product, index) => (
                 <CSSTransition key={index} timeout={500} classNames="product-item">
                  <div className="col mb-4" key={index} onClick={() => navigateToProduct(index)}>
                      <div className="card h-100"  style = {{cursor : 'pointer' , transition : "transform 0.3s"}}>
                        {product.bestselling == 'yes' && (
                          <span className="best-seller-tag">Best Seller</span>
                        )}
                      <img src={product.image} className="card-img-top" alt={`Product ${index + 1}`} />
                      <div className="card-body">
                          <h5 className="home-card-title">{product.name}</h5>
                          <p className="card-text">{product.description}</p>
                          <p className="card-text">${product.price}</p>
                      </div>
                      </div>
                  </div>
                </CSSTransition>
                ))}
              </TransitionGroup>
              <div className="text-center">
                <button className={`load-more ${showAll ? 'show-less' : ''}`} onClick={handleLoadMore}>
                  {showAll ? (
                    <>
                      <span className="button-text">Show Less</span> <FiArrowUp size={16} />
                    </>
                  ) : (
                    <>
                      <span className="button-text">Load More</span> <FiArrowDown size={16} />
                    </>
                  )}
                </button>
              </div>
            </div>
  
            {/* FOOTER SECTION */}

            <footer className="footer">
              <div className="footer-content">
                  <div className="footer-column mb-4">
                  <div>
                      <h2 className="footer-heading">ESPIRIT LLC</h2>
                  </div>
                  </div>
                  <div className="footer-column mb-4">
                  <div>
                      <p className="footer-heading">Address</p>
                      <br />
                      <p>34 N Franklin Ave Ste 687 2116</p>
                      <p>Pinedale, Wyoming</p>
                      <p>82941</p>
                      <p>©2018 by ESPIRIT LLC</p>
                  </div>
                  </div>
                  <div className="footer-column">
                  <div>
                      <p className="footer-heading">Contact</p>
                      <br />
                      <p>admin@espiritllc.com</p>
                      <p>+13022084083</p>
                  </div>
                  </div>
              </div>
            </footer>




        </div>
    );

}

export default Shop;