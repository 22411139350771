import React , {useState , useEffect, useRef} from 'react';
import './About.css';
import logo from "../../images/logo.jpeg"


import { Button } from 'react-bootstrap';
import { Link } from 'react-scroll';
import { Link as RouterLink } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaRupeeSign, FaShoppingBag } from 'react-icons/fa';


import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';



const About = () => {

    const navigate = useNavigate();

    const navigateToHomePage = () => {
        navigate('/?fromContact=true');
      };


    return (
        <div>
        {/* Navbar */}
            <div className="navbar px-5">
                <div className="d-flex align-items-center">
                    <img className="logo" src={logo} alt="Logo" />
                    <div className="ms-3">
                    <h2 className="navbar-heading" style={{marginTop:'3%'}}>ESPIRIT LLC</h2>
                    <p className="navbar-text text-white" style={{marginTop:'-3%'}}>Reliable Products, Reliable Partnership</p>
                    </div>
                </div>
                <a className="contact-button" onClick={navigateToHomePage}>
                    Contact
                </a>
                </div>
                <div className="sub-navbar bg-light py-2 px-5">
                <div className="row align-items-center px-0">
                    <div className="col-12 col-md-6">
                    <ul className="nav">
                        <li className="nav-item">
                        <RouterLink to = "/" className="nav-link">Home</RouterLink>
                        </li>
                        <li className="nav-item">
                        <RouterLink className="nav-link" to="/shop">Shop</RouterLink>
                        </li>
                        <li className="nav-item">
                        <RouterLink className="nav-link active" to="/About">
                            About Us
                            <span className="active-indicator"></span>
                        </RouterLink>
                        </li>

                        <li className="nav-item">
                        <RouterLink className="nav-link" to="/FAQs">FAQs</RouterLink>
                        </li>
                        <li className="nav-item">
                        <RouterLink className="nav-link" to="/Blog">Blog</RouterLink>
                        </li>
                    </ul>
                    </div>
                    <div className="col-12 col-md-6 text-md-end">
                    <span className="email-text">admin@espiritllc.com</span>
                    <FaShoppingBag className="shopping-bag-icon" color="black" size={20} style={{ fill: 'none', stroke: 'black', strokeWidth: 20 , marginBottom: 5 }} />
                    </div>
                </div>
            </div>
            

            {/* About JSX */}
            <div className="about row">
                <h1>ABOUT US {'\n'} </h1>
                <h4 style={{marginBottom:'2%' , fontWeight:'normal'}}>Your Go-To ECommerce Store. {'\n'} </h4>
                <h5>WHO ARE WE?</h5>
                <span>Welcome to ESPIRITLLC! We are a leading online retailer dedicated to bringing you high-quality products at competitive prices. Our mission is to connect customers with a wide range of products sourced from trusted wholesalers, providing a seamless and convenient shopping experience.
                    We are renowned for being associated with top-notch quality, unwavering commitment to sustainability, and unparalleled reliability. We take pride in our long history of satisfied customers and eagerly anticipate the opportunity to continue serving them in the future. Feel free to browse our website to explore our wide-ranging selection of products {'\n'} </span>
                <h5>WHY US? </h5>
                <span>As a seller, we take pride in our strong relationships with reputable wholesalers, carefully curating our product selection to ensure that only the best items make it to our virtual shelves. Our team works tirelessly to handpick products that meet our strict quality standards, offering you products that are reliable, durable, and designed to enhance your everyday life.
                    We understand that customer satisfaction is the foundation of our success, and we are committed to delivering outstanding service at every step of your shopping journey. From browsing our user-friendly website to placing an order, our goal is to provide you with a smooth, secure, and enjoyable online shopping experience.  {'\n'} </span>
                <h5>OUR MOTTO! </h5>
                <span>At ESPIRITLLC, we believe in building trust with our customers by delivering on our promises. We are dedicated to providing accurate product descriptions, clear pricing, and prompt shipping to ensure that you receive your order in a timely manner. Our responsive customer service team is always available to address any questions or concerns you may have, providing you with personalized assistance and support. {'\n'}</span>
                <span>We look forward to serving you and exceeding your expectations with our reliable products and exceptional service.</span>
            </div>


            {/* FOOTER JSX */}
            <footer className="footer">
                <div className="footer-content">
                    <div className="footer-column mb-4">
                    <div>
                        <h2 className="footer-heading">Espirit LLC</h2>
                    </div>
                    </div>
                    <div className="footer-column mb-4">
                    <div>
                        <p className="footer-heading">Address</p>
                        <br />
                        <p>34 N Franklin Ave Ste 687 2116</p>
                        <p>Pinedale, Wyoming</p>
                        <p>82941</p>
                        <p>©2018 by ESPIRIT LLC</p>
                    </div>
                    </div>
                    <div className="footer-column">
                    <div>
                        <p className="footer-heading">Contact</p>
                        <br />
                        <p>admin@espiritllc.com</p>
                        <p>+13022084083</p>
                    </div>
                    </div>
                </div>
            </footer>



        </div>

    );

}

export default About