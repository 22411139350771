import React , {useState , useEffect, useRef} from 'react';
import './Home.css';
import logo from "../../images/logo.jpeg"
import parcel from "../../images/parcel.png"
import image1 from "../../images/product1.jpg"
import image2 from "../../images/product2.jpg"
import image3 from "../../images/product3.jpg"
import image4 from "../../images/product4.jpg"
import image5 from "../../images/product5.jpg"
import about from "../../images/About.jpeg"
import contact from "../../images/Contact.jpg"



import { CSSTransition, TransitionGroup } from 'react-transition-group';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Button } from 'react-bootstrap';
import { Link } from 'react-scroll';
import { Link as RouterLink } from 'react-router-dom';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faArrowUp, faArrowDown } from '@fortawesome/free-solid-svg-icons';
import { FiArrowUp, FiArrowDown } from 'react-icons/fi';
import { FaShoppingBag } from 'react-icons/fa';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';




import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import { useNavigate } from 'react-router-dom';


const HomePage = () => {

  const location = useLocation();

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const fromContact = query.get('fromContact');

    if (fromContact === 'true') {
      // Scroll to the element with the ID "#contact"
      const contactSection = document.getElementById('contact');
      if (contactSection) {
        contactSection.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location]);


  const [isSubmitted, setIsSubmitted] = useState(false);


  // PRODUCTS ARRAY 
  // ______________________________________________________________________


  const products = [
    {
      image: image1,
      name: 'Multi Purpose Blender Black',
      description: 'This is used for making juices',
      price: 19.99,
      bestselling: 'yes'
    },
    {
      image: image2,
      name: 'Clipper For Mobile Phones',
      description: 'Clips for mobile cameras and trigger purposes',
      price: 23.75,
      bestselling: 'yes'

    },
    {
      image: image3,
      name: 'Multi Color Hair Brushes',
      description: 'Soft and strong hair brushes for every kind of hair',
      price: 15.50,
      bestselling: 'no'
    },
    {
      image: image4,
      name: 'FootWear Grey & White',
      description: 'Shoes for your everywhere to go.',
      price: 12.99,
      bestselling: 'yes'

    },
    {
      image: image5,
      name: 'Canon Camera Black Color',
      description: 'High end camera for high end pictures',
      price: 29.99,
      bestselling: 'no'
    },
    {
      image: image1,
      name: 'Multi Purpose Blender Black',
      description: 'This is used for making juices',
      price: 17.49,
      bestselling: 'yes'

    },
    {
      image: image2,
      name: 'Clipper For Mobile Phones',
      description: 'Clips for mobile cameras and trigger purposes',
      price: 9.99,
      bestselling: 'yes'
    },
    {
      image: image3,
      name: 'Multi Color Hair Brushes',
      description: 'Soft and strong hair brushes for every kind of hair',
      price: 21.25,
      bestselling: 'no'
    },
    {
      image: image4,
      name: 'FootWear Grey & White',
      description: 'Shoes for your everywhere to go.',
      price: 16.75,
      bestselling: 'no'
    },
    {
      image: image5,
      name: 'Canon Camera Black Color',
      description: 'High end camera for high end pictures',
      price: 14.99,
      bestselling: 'no'
    },
    {
      image: image1,
      name: 'Multi Purpose Blender Black',
      description: 'This is used for making juices',
      price: 27.50,
      bestselling: 'yes'
    },
    {
      image: image2,
      name: 'Clipper For Mobile Phones',
      description: 'Clips for mobile cameras and trigger purposes',
      price: 18.99,
      bestselling: 'yes'
    },
    {
      image: image3,
      name: 'Multi Color Hair Brushes',
      description: 'Soft and strong hair brushes for every kind of hair',
      price: 11.99,
      bestselling: 'no'
    },
    {
      image: image4,
      name: 'FootWear Grey & White',
      description: 'Shoes for your everywhere to go.',
      price: 24.50,
      bestselling: 'no'
    },
    {
      image: image5,
      name: 'Canon Camera Black Color',
      description: 'High end camera for high end pictures',
      price: 8.95,
      bestselling: 'yes'
    },
    {
      image: image1,
      name: 'Multi Purpose Blender Black',
      description: 'This is used for making juices',
      price: 19.99,
      bestselling: 'no'
    },
    {
      image: image2,
      name: 'Clipper For Mobile Phones',
      description: 'Clips for mobile cameras and trigger purposes',
      price: 13.25,
      bestselling: 'no'
    },
    {
      image: image3,
      name: 'Multi Color Hair Brushes',
      description: 'Soft and strong hair brushes for every kind of hair',
      price: 22.75,
      bestselling: 'yes'
    },
    {
      image: image4,
      name: 'FootWear Grey & White',
      description: 'Shoes for your everywhere to go.',
      price: 10.99,
      bestselling: 'no'
    },
    {
      image: image5,
      name: 'Canon Camera Black Color',
      description: 'High end camera for high end pictures',
      price: 16.50,
      bestselling: 'no'
    },
  ];

  // ____________________________________________________________________________________


  const productContainerRef = useRef(null);
  const [displayedProducts, setDisplayedProducts] = useState(products.slice(0, 8));
  const [showAll, setShowAll] = useState(false);

  const handleLoadMore = () => {
    setShowAll(!showAll);
    if (!showAll) {
      setDisplayedProducts(products);
    } else {
      setDisplayedProducts(products.slice(0, 8));
          // Scroll to the product section after sliding up
      productContainerRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }


  };

    const images = [
        image1,
        image2,
        image3,
        image4,
        image5,
        image1,
        image2,
        image3,
        image4,
        image5,
  
        // ...add the rest of the imported images here
      ];
   


      const [currentIndex, setCurrentIndex] = useState(2);

      const handlePrev = () => {
        setCurrentIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
      };
    
      const handleNext = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
      };
    
      const displayedImages = [];
      const numDisplayedImages = getWindowSize() <= 768 ? 1 : getWindowSize() <= 1200 ? 3 : 5;
    
      useEffect(() => {
        const handleResize = () => {
          setCurrentIndex(2); // Reset the current index on window resize
        };
        window.addEventListener('resize', handleResize);
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []);
    
      for (let i = currentIndex - Math.floor(numDisplayedImages / 2); i <= currentIndex + Math.floor(numDisplayedImages / 2); i++) {
        displayedImages.push(images[(i + images.length) % images.length]);
      }
    
      function getWindowSize() {
        return window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
      }

      




      const handleSubmit = (e) => {
        e.preventDefault(); // Prevent default form submission
        setIsSubmitted(true);
        setTimeout(() => {
          setIsSubmitted(false);
        }, 3000); // Set timeout for 5 seconds
      };
      
      
    return (
        <div>


          {/* Navbar */}
          <div className="navbar px-5">
            <div className="d-flex align-items-center">
              <img className="logo" src={logo} alt="Logo" />
              <div className="ms-3">
                <h2 className="navbar-heading" style={{marginTop:'3%'}}>ESPIRIT LLC</h2>
                <p className="navbar-text text-white" style={{marginTop:'-3%'}}>Reliable Products, Reliable Partnership</p>
              </div>
            </div>
            <Link to="contact" smooth={true} duration={300} className="contact-button">
              Contact
            </Link>
          </div>
          <div className="sub-navbar bg-light py-2 px-5">
            <div className="row align-items-center px-0">
              <div className="col-12 col-md-6">
                <ul className="nav">
                  <li className="nav-item">
                    <RouterLink className="nav-link active" to="/">
                      Home
                      <span className="active-indicator"></span>
                    </RouterLink>
                  </li>
                  <li className="nav-item">
                    <RouterLink to="/Shop" className="nav-link">Shop</RouterLink>
                  </li>
                  <li className="nav-item">
                    <RouterLink className="nav-link" to="/About">About Us</RouterLink>
                    </li>
                    <li className="nav-item">
                    <RouterLink className="nav-link" to="/FAQs">FAQs</RouterLink>
                    </li>
                    <li className="nav-item">
                    <RouterLink to="/Blog" className="nav-link" href="/">Blog</RouterLink>
                    </li>
                </ul>
              </div>
              <div className="col-12 col-md-6 text-md-end">
                <span className="email-text">admin@espiritllc.com</span>
                <FaShoppingBag className="shopping-bag-icon" color="black" size={20} style={{ fill: 'none', stroke: 'black', strokeWidth: 20 , marginBottom: 5 }} />
              </div>
            </div>
          </div>

    

    
    {/* Middle Text */}
          <div className="heading my-5 mt-lg-10 mb-4">
            <div className="container">
              <div className="text-center">
                <h1 className="display-1 fw-bold">
                  Your Trusted Source
                  <br />
                  For Quality Products
                </h1>
              </div>
            </div>
          </div>





        {/* PARCEL IMAGE  */}
            <div className='parcel'>
                <img src = {parcel} alt="" />
            </div>

            <div>

            </div>



        {/* SLIDER FUNCTION */}
          <div className="carousel mx-5" style={{padding: '0 4%'}}>
            <div className="row align-items-center justify-content-center">
              {displayedImages.map((image, index) => (
                <div className="col text-center" key={index}>
                  <img
                    src={image}
                    alt={`Image ${index + 1}`}
                    className={`carousel-image ${index === Math.floor(numDisplayedImages / 2) ? 'active' : ''}`}
                  />
                </div>
              ))}
            </div>
            <div className="row align-items-center justify-content-center">
              <div className="col text-center mt-4">
                <Button className="prev-button bg-white text-black mx-2" onClick={handlePrev}>
                  &lt;
                </Button>
                <Button className="next-button bg-white text-black mx-2" onClick={handleNext}>
                  &gt;
                </Button>
              </div>
            </div>
          </div>



          {/* ABOUT SECTION */}

          <div className="about">
            <div className="left-part">
              <h3>Who are we?</h3>
              <br></br>
              <br></br>
              <h3><em>Your Go-To ECommerce Store.</em></h3>
              <p>Welcome to ESPIRITLLC! We are a leading online retailer dedicated to bringing you high-quality products at competitive prices. Our mission is to connect customers with a wide range of products sourced from trusted wholesalers, providing a seamless and convenient shopping experience.
              We are renowned for being associated with top-notch quality, unwavering commitment to sustainability, and unparalleled reliability. We take pride in our long history of satisfied customers and eagerly anticipate the opportunity to continue serving them in the future. Feel free to browse our website to explore our wide-ranging selection of products..</p>
            </div>
            <div className="right-part">
              <img src={about} alt="About Image" />
            </div>
        </div>



        {/* PRODUCT JSX */}

        <div className="container product-container pt-5" ref={productContainerRef}>
          <TransitionGroup className="row row-cols-1 row-cols-md-2 row-cols-lg-4 mx-4">
            {displayedProducts.map((product, index) => (
              <CSSTransition key={index} timeout={500} classNames="product-item">
                <div className="col mb-4">
                  <div className="card h-100">
                    {product.bestselling == 'yes' && (
                      <span className="best-seller-tag">Best Seller</span>
                    )}
                    <img src={product.image} className="card-img-top" alt={`Product ${index + 1}`} />
                    <div className="card-body">
                      <h5 className="home-card-title">{product.name}</h5>
                      <p className="card-text">{product.description}</p>
                      <p className="card-text">${product.price}</p>
                    </div>
                  </div>
                </div>
              </CSSTransition>
            ))}
          </TransitionGroup>
          <div className="text-center">
            <button className={`load-more ${showAll ? 'show-less' : ''}`} onClick={handleLoadMore}>
              {showAll ? (
                <>
                  <span className="button-text">Show Less</span> <FiArrowUp size={16} />
                </>
              ) : (
                <>
                  <span className="button-text">Load More</span> <FiArrowDown size={16} />
                </>
              )}
            </button>
          </div>
        </div>





        {/* CONTACT SECTION */}
        <section id='contact'>
          <div className="contact">
            <div className="left-section">
              <h2 className="contact-heading">Contact Us</h2>
              <form className="contact-form" onSubmit={handleSubmit}>
                <div className="form-group">
                  <label htmlFor="name">Name <span className="required">*</span></label>
                  <input type="text" id="name" name="name" required placeholder='Enter Your Name' />
                </div>
                <div className="form-group">
                  <label htmlFor="email">Email <span className="required">*</span></label>
                  <input type="email" id="email" name="email" required  placeholder='Enter Your Email' />
                </div>
                <div className="form-group">
                  <label htmlFor="subject">Subject</label>
                  <input type="text" id="subject" name="subject"  placeholder='Type Your Subject' />
                </div>
                <div className="form-group">
                  <label htmlFor="message">Message</label>
                  <textarea id="message" name="message"  placeholder='Type Your Message Here....'></textarea>
                </div>
                <button type="submit" className="submit-button">Submit</button>
              </form>
              {isSubmitted && (
                <p className="fade-in-out">Thanks for Submitting!</p>
              )}
            </div>
            <div className="right-section">
              <img src={contact} alt="Contact" className="contact-image" />
            </div>
          </div>
        </section>


        {/* FOOTER SECTION */}

        <footer className="footer">
          <div className="footer-content">
            <div className="footer-column mb-4">
              <div>
                <h2 className="footer-heading">ESPIRIT LLC</h2>
              </div>
            </div>
            <div className="footer-column mb-4">
              <div>
                <p className="footer-heading">Address</p>
                <br />
                <p>34 N Franklin Ave Ste 687 2116</p>
                <p>Pinedale, Wyoming</p>
                <p>82941</p>
                <p>©2018 by ESPIRIT LLC</p>
              </div>
            </div>
            <div className="footer-column">
              <div>
                <p className="footer-heading">Contact</p>
                <br />
                <p>admin@espiritllc.com</p>
                <p>+13022084083</p>
              </div>
            </div>
          </div>
        </footer>





        </div>
      );
      
  }
  
export default HomePage;