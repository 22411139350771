import React , {useState , useEffect} from 'react';
import './Blog.css';
import blog1 from "../../images/blog1.jpeg";
import blog2 from "../../images/blog2.jpeg";
import blog3 from "../../images/blog3.png"
 import logo from "../../images/logo.jpeg"
// import parcel from "../../images/parcel.png"


import { Card,Button , Row, Col} from 'react-bootstrap';
import { Link } from 'react-scroll';
import { Link as RouterLink } from 'react-router-dom';

import { FiArrowUp, FiArrowDown , FiSearch } from 'react-icons/fi';
import { FaSearch, FaShoppingBag , FaEllipsisV, FaHeart } from 'react-icons/fa';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';




import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import { useNavigate } from 'react-router-dom';


const BlogPage = () => {

  const navigate = useNavigate();

  const navigateToHomePage = () => {
    navigate('/?fromContact=true');
  };

  const location = useLocation();
  const [isSubmitted, setIsSubmitted] = useState(false);

  // BLOGS ARRAY 

  const blogs = [
    {
        image: blog1,
        name : 'espiritllc99',
        date : 'Apr 16',
        time: '2',
        title: 'Different Products One Can Buy From Every Corner of the World Online',
        description: "In today's digital era, the internet has made the world a global marketplace, allowing us to access and purchase products from every...",
        views: '0',
        comments: '0'
    },

    {
        image: blog2,
        name : 'espiritllc99',
        date : 'Apr 16',
        time: '2',
        title: "The Use of Technology in Today's World",
        description: "In today's fast paced word, the internet has made the world a global marketplace, allowing us to access and purchase products from every...",
        views: '5',
        comments: '10'   
    },

    {
      image: blog3,
      name : 'espiritllc99',
      date : 'Apr 16',
      time: '3',
      title: "Maximising Savings with Online Coupons and Promo Codes",
      description: "If you're an avid online shopper, you're likely familiar with the trill of scoring a great deal with coupons and promo code, These...",
      views: '251',
      comments: '30'   
  }

  ]



      
    return (
        <div>


          {/* Navbar */}
          <div className="navbar px-5">
            <div className="d-flex align-items-center">
              <img className="logo" src={logo} alt="Logo" />
              <div className="ms-3">
                <h2 className="navbar-heading" style={{marginTop:'3%'}}>ESPIRIT LLC</h2>
                <p className="navbar-text text-white" style={{marginTop:'-3%'}}>Reliable Products, Reliable Partnership</p>
              </div>
            </div>
            <a className="contact-button" onClick={navigateToHomePage}>
                    Contact
            </a>
          </div>
          <div className="sub-navbar bg-light py-2 px-5">
            <div className="row align-items-center px-0">
              <div className="col-12 col-md-6">
                <ul className="nav">
                  <li className="nav-item">
                    <li className="nav-item">
                      <RouterLink to="/" className="nav-link" href="/">Home</RouterLink>
                    </li>
                  </li>
                  <li className="nav-item">
                    <RouterLink to="/Shop" className="nav-link">Shop</RouterLink>
                  </li>
                  <li className="nav-item">
                    <RouterLink className="nav-link" to="/About">About Us</RouterLink>
                    </li>
                    <li className="nav-item">
                    <RouterLink className="nav-link" to="/FAQs">FAQs</RouterLink>
                    </li>
                    <RouterLink className="nav-link active" to="/Blog">
                      Blog
                      <span className="active-indicator"></span>
                    </RouterLink>

                </ul>
              </div>
              <div className="col-12 col-md-6 text-md-end">
                <span className="email-text">admin@espiritllc.com</span>
                <FaShoppingBag className="shopping-bag-icon" color="black" size={20} style={{ fill: 'none', stroke: 'black', strokeWidth: 20 , marginBottom: 5 }} />
              </div>
            </div>
          </div>

          {/* MAIN HEADING ALONG WITH LOGIN BUTTON */}
          <div class="heading my-3 mt-sm-2 mb-2">
            <div class="container">
              <div class="row align-items-center">
                <div class="col-sm-6">
                  <div class="text-left">
                    <h3 class="display-4 fw-bold" >
                      All Posts
                    </h3>
                  </div>
                </div>
                <div class="col-sm-3">
                <FaSearch class="search-btn" color="black" size={20} style= {{ marginLeft: '70%'}}/>
              
                </div>
                <div class="col-sm-3">
                  
                  <button 
                  
                  class="login-btn">Login / Signup</button>
                </div>
              </div>
            </div>
        </div>


 {/* Blogs */}



 {blogs.map((blog, index) => ( 
      <>
<div class="custom-card mb-5" key={index}>

<img src={blog.image} alt="Image" class="card-image" /><div class="blog-card-body">
        <div class="avatar">
            <img src={logo} alt="Avatar" class="avatar-image" />
        </div>
        <div className="user-info">
          <div>
            <h5 className="user-name">{blog.name}</h5>
            <p className="user-date">
              {blog.date}. {blog.time} min
            </p>
          </div>
          <div className="menu">
            <FaEllipsisV className="menu-icon" color="grey" size={20} />
          </div>
        </div>
        <div class="text-area">
            <h3 class="card-title">{blog.title}</h3>
            <p class="card-description">{blog.description}</p>
            <hr class="divider" />
        </div>

        <div class="views px-5 mx-4 w-100">
            <h6>{blog.views} views     {blog.comments} comments</h6>
            <div class="like">
            
    <FaHeart className="like-icon" color="red" size={20} fill='none' style={{stroke: 'red', strokeWidth : 30}} />
  </div>
            
        </div>
    </div>
    </div>
    </>
   ))}



          

    

    
    
        {/* FOOTER SECTION */}

        <footer className="footer">
          <div className="footer-content">
            <div className="footer-column mb-4">
              <div>
                <h2 className="footer-heading">ESPIRIT LLC</h2>
              </div>
            </div>
            <div className="footer-column mb-4">
              <div>
                <p className="footer-heading">Address</p>
                <br />
                <p>34 N Franklin Ave Ste 687 2116</p>
                <p>Pinedale, Wyoming</p>
                <p>82941</p>
                <p>©2018 by ESPIRIT LLC</p>
              </div>
            </div>
            <div className="footer-column">
              <div>
                <p className="footer-heading">Contact</p>
                <br />
                <p>admin@espiritllc.com@gmail.com</p>
                <p>+13022084083</p>
              </div>
            </div>
          </div>
        </footer>





        </div>
      );
      
  }
  
export default BlogPage;