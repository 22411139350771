
import './App.css';
import { BrowserRouter as Router, Routes, Route, Link,Switch } from 'react-router-dom';
import HomePage from './components/HomeScreen/Home';
import Shop from './components/ShopScreen/Shop'
import Product from './components/ProductScreen/Product';
import BlogPage from './components/Blog/Blog';
import About from './components/AboutScreen/About';
import FAQ from './components/FAQs/faqs';


function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage/>}/>
        <Route path = "/Shop" element={<Shop/>} />
        <Route path = '/Product' element={<Product/>} />
        <Route path ='/About' element={<About/>}/>
        <Route path ='/Blog' element={<BlogPage/>}/>
        <Route path ='/FAQs' element={<FAQ/>}/>
      </Routes>
    </Router>
  );
}

export default App;
