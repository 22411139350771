import React , {useState , useEffect} from 'react';
import logo from "../../images/logo.jpeg"
import image1 from "../../images/product1.jpg"
import image2 from "../../images/product2.jpg"
import image3 from "../../images/product3.jpg"
import image4 from "../../images/product4.jpg"
import image5 from "../../images/product5.jpg"
import "./Product.css"
import "../HomeScreen/Home.css"



import { Link as RouterLink } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { Link } from 'react-scroll';
import { useLocation } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';

import { animateScroll as scroll } from 'react-scroll';

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faArrowUp, faArrowDown } from '@fortawesome/free-solid-svg-icons';
import { FiArrowUp, FiArrowDown } from 'react-icons/fi';
import { FiMail } from 'react-icons/fi';
import { FaRupeeSign, FaShoppingBag } from 'react-icons/fa';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';



import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import { useNavigate } from 'react-router-dom';
import { prettyDOM } from '@testing-library/react';


const Product = () => {

    const location = useLocation();
    const { products, index } = location.state;
    // console.log(products[index].name)

    const [currentIndex, setCurrentIndex] = useState(index);
    const navigate = useNavigate();
  
    const handlePrev = () => {
      setCurrentIndex((prevIndex) => (prevIndex === 0 ? products.length - 1 : prevIndex - 1));
    };
  
    const handleNext = () => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % products.length);
    };
  
    const product = products[currentIndex];
  
    const navigateToHomePage = () => {
      navigate('/?fromContact=true');
    };



    const [quantity, setQuantity] = useState(1);

    const handleQuantityChange = (event) => {
      const newQuantity = parseInt(event.target.value);
      if (newQuantity >= 1) {
        setQuantity(newQuantity);
      }
    };


    const [showDescription1, setShowDescription1] = useState(false);
    const [showDescription2, setShowDescription2] = useState(false);
    const [showDescription3, setShowDescription3] = useState(false);
  
    // Function to toggle the first description
    const handleToggleDescription1 = () => {
      setShowDescription1((prev) => !prev);
    };
  
    // Function to toggle the second description
    const handleToggleDescription2 = () => {
      setShowDescription2((prev) => !prev);
    };
  
    // Function to toggle the third description
    const handleToggleDescription3 = () => {
      setShowDescription3((prev) => !prev);
    };

      return(

        <div>
            {/* Navbar */}
            <div className="navbar px-5">
                <div className="d-flex align-items-center">
                    <img className="logo" src={logo} alt="Logo" />
                    <div className="ms-3">
                        <h2 className="navbar-heading" style={{marginTop:'3%'}}>ESPIRIT LLC</h2>
                        <p className="navbar-text text-white" style={{marginTop:'-3%'}}>Reliable Products, Reliable Partnership</p>
                    </div>
                </div>
                <a className="contact-button" onClick={navigateToHomePage}>
                    Contact
                </a>
                </div>
                <div className="sub-navbar bg-light py-2 px-5">
                <div className="row align-items-center px-0">
                    <div className="col-12 col-md-6">
                    <ul className="nav">
                        <li className="nav-item">
                        <RouterLink to = "/" className="nav-link">Home</RouterLink>
                        </li>
                        <li className="nav-item">
                        <RouterLink className="nav-link active" to="/shop">
                            Shop
                            <span className="active-indicator"></span>
                        </RouterLink>
                        </li>
                        <li className="nav-item">
                        <RouterLink className="nav-link" to="/About">About Us</RouterLink>
                        </li>
                        <li className="nav-item">
                        <RouterLink className="nav-link" to="/FAQs">FAQs</RouterLink>
                        </li>
                        <li className="nav-item">
                        <RouterLink className="nav-link" to="/Blog">Blog</RouterLink>
                        </li>
                    </ul>
                    </div>
                    <div className="col-12 col-md-6 text-md-end">
                    <span className="email-text">admin@espiritllc.com</span>
                    <FaShoppingBag className="shopping-bag-icon" color="black" size={20} style={{ fill: 'none', stroke: 'black', strokeWidth: 20 , marginBottom: 5 }} />
                    </div>
                </div>
            </div>


            {/* PRODUCT JSX */}
            
            <div className="container mt-5 pt-5" style={{marginBottom:'6%'}}>
                <div className="row justify-content-center">
                    <div className="col-lg-5 mx-2">
                        <div className="left-container">
                            <p>Home / {product.description}</p>
                        <img src={product.image} alt="Product" className="img-fluid" style={{marginBottom:"3%", height: '80%', objectFit: 'contain' ,  width: "100%" }} />
                        <span>Introducing our revolutionary and cutting-edge product that redefines modern design and functionality. Crafted with meticulous attention to detail and built to exceed expectations, this remarkable product is a true marvel of innovation. Its sleek and sophisticated aesthetics effortlessly complement any setting, while its user-friendly features ensure an intuitive and seamless experience.<br/>Designed to surpass industry standards, this product is made from premium materials, guaranteeing unparalleled durability and performance. With state-of-the-art technology and precision engineering, it delivers exceptional results with every use. Embrace the ultimate in convenience and efficiency as this product adapts effortlessly to your lifestyle.<br/>
                        From its ergonomic design that prioritizes comfort to its multifunctionality that enhances productivity, this product is a game-changer in every sense. Experience the future of convenience and stay ahead in this fast-paced world. Elevate your daily routine with this extraordinary product, your go-to companion for a life of convenience and sophistication. Get ready to embark on a journey of elevated living with this extraordinary product at your side.</span>
                        </div>
                    </div>
                    <div className="col-lg-5 mx-2">
                        <div className="right-container d-flex flex-column justify-content-end">
                            <div className="buttons-container" style = {{alignSelf: "flex-end"}}>
                                <button
                                className="btn btn-secondary bg-transparent border-0"
                                onClick={handlePrev}
                                style={{ height: '3%', color: 'black' }}
                                >
                                <FiChevronLeft size={30} /> Prev
                                </button>
                                <span className="divider-vertical"></span>
                                <button
                                className="btn btn-secondary bg-transparent border-0"
                                onClick={handleNext}
                                style={{ height: '3%', color: 'black',  }}
                                >
                                Next <FiChevronRight size={30} />
                                </button>
                            </div>
                            <div className="mt-4">
                                <h4 style={{ fontFamily: "'Times New Roman', Times, serif" }}>{product.name}</h4>
                                <p style={{ fontFamily: "'Times New Roman', Times, serif" }}>SKU: 1111ad111111a1</p>
                                <br />
                                <div style={{ fontFamily: "'Times New Roman', Times, serif", fontWeight: 'bold' }}>
                                    <span style={{ fontSize: '2.1rem' ,  fontFamily: "system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;"}}>$</span>
                                    <span style={{ fontSize: '1.8rem', fontFamily: "'Times New Roman', Times, serif" , verticalAlign: 'sub' }}>{parseInt(product.price)}</span>
                                    <span style={{ fontSize: '1rem', fontFamily: "'Times New Roman', Times, serif" }}>{(product.price % 1).toFixed(2).slice(1)}</span>
                                </div>
                                <br />
                                <p style={{ fontFamily: "'Times New Roman', Times, serif" }}>Quantity</p>
                                <input
                                    className = "my-2"
                                    type="number"
                                    value={quantity}
                                    min="1"
                                    onChange={handleQuantityChange}
                                    style={{
                                    fontFamily: "'Times New Roman', Times, serif",
                                    borderColor: '#e2dddd',
                                    backgroundColor: 'inherit',
                                    borderWidth: '1px',
                                    borderStyle: 'solid',
                                    width: '20%',
                                    textAlign: 'left',
                                    }}
                                />
                                  <Button
                                    className = "cart-button my-2"
                                    variant="dark" // Set the background color to the same as borders
                                    style={{ width: '100%', color: 'white', backgroundColor: '#e2dddd', borderColor: '#e2dddd', }} // Set width to 100% and text color to white
                                >
                                    Add to Cart
                                </Button>
                                <div className='container mt-2 py-2'>
                                    <div className="product-description" onClick={handleToggleDescription1}>
                                        <h3 className="product-description-heading" >PRODUCT DESCRIPTION</h3>
                                        <button
                                            className={`description-toggle ${showDescription1 ? 'open' : ''}`}
                                            
                                            >
                                            {showDescription1 ? '-' : '+'}
                                        </button>
                                    </div>
                                    <CSSTransition
                                        in={showDescription1}
                                        timeout={300}
                                        classNames="description-content"
                                        unmountOnExit
                                    >
                                        <div className="description-content py-2">
                                            <p>Introducing our innovative product, designed to enhance your daily life with seamless functionality and unmatched performance. This cutting-edge solution brings together advanced technology and sleek design, providing you with a versatile and reliable companion for all your needs. With its user-friendly interface and state-of-the-art features, our product promises to revolutionize the way you experience convenience and efficiency. Embrace the future with confidence, as our product takes you on a transformative journey of enhanced productivity and unrivaled satisfaction. Elevate your lifestyle and embrace the possibilities with our exceptional product.</p>
                                        </div>
                                    </CSSTransition>
                                    <hr className="description-divider" />
                                    <div className="product-description" onClick={handleToggleDescription2}>
                                        <h3 className="product-description-heading">RETURN & REFFUND POLICY</h3>
                                        <button
                                            className={`description-toggle ${showDescription2 ? 'open' : ''}`}
                                            
                                            >
                                            {showDescription2 ? '-' : '+'}
                                        </button>
                                    </div>
                                    <CSSTransition
                                        in={showDescription2}
                                        timeout={300}
                                        classNames="description-content"
                                        unmountOnExit
                                    >
                                        <div className="description-content py-2">
                                            <p>Our refund policy is designed to prioritize your satisfaction and peace of mind. If for any reason you are not completely satisfied with your purchase, we offer a hassle-free return process within [number of days] from the date of delivery. To be eligible for a refund, the product must be unused, in its original condition, and accompanied by the original proof of purchase. Once the return is received and inspected, we will initiate the refund process promptly. Please note that shipping and handling charges are non-refundable. At [Company Name], we strive to ensure a smooth and transparent refund experience, providing you with the confidence to explore our products risk-free. Your satisfaction is our priority, and we are committed to delivering exceptional customer service at every step.</p>
                                        </div>
                                    </CSSTransition>
                                    <hr className="description-divider" />
                                    <div className="product-description" onClick={handleToggleDescription3}>
                                        <h3 className="product-description-heading">SHIPPING INFO</h3>
                                        <button
                                            className={`description-toggle ${showDescription3 ? 'open' : ''}`}
                                            
                                            >
                                            {showDescription3 ? '-' : '+'}
                                        </button>
                                    </div>
                                    <CSSTransition
                                        in={showDescription3}
                                        timeout={300}
                                        classNames="description-content"
                                        unmountOnExit
                                    >
                                        <div className="description-content py-2">
                                            <p>At ESPIRIT LLC, we strive to provide a seamless and reliable shipping experience. We offer various shipping options, both domestic and international, to suit your needs. Your order will be processed promptly, and you'll receive a tracking number for easy monitoring. Rest assured, your package will be handled with care, ensuring it arrives safely. For any queries, our dedicated customer support team is here to assist you. Trust us to bring your purchases right to your doorstep.</p>
                                        </div>
                                    </CSSTransition>
                                    <hr className="description-divider" />
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>


                    {/* FOOTER SECTION */}

            <footer className="footer">
                <div className="footer-content">
                    <div className="footer-column mb-4">
                    <div>
                        <h2 className="footer-heading">Espirit LLC</h2>
                    </div>
                    </div>
                    <div className="footer-column mb-4">
                    <div>
                        <p className="footer-heading">Address</p>
                        <br />
                        <p>34 N Franklin Ave Ste 687 2116</p>
                        <p>Pinedale, Wyoming</p>
                        <p>82941</p>
                        <p>©2018 by ESPIRIT LLC</p>
                    </div>
                    </div>
                    <div className="footer-column">
                    <div>
                        <p className="footer-heading">Contact</p>
                        <br />
                        <p>e.spiritllc99@gmail.com</p>
                        <p>+13022084083</p>
                    </div>
                    </div>
                </div>
            </footer>



        </div>
      );


}

export default Product